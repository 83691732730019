@use 'sass:map';
@use '../../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../../../themeFactory/utils.scss' as *;

@mixin layoutStyles($layoutTokens) {
    @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens) {
    @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens) {
    color: map.get($stateTokens, textColor);
    @debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens) {

    $visible: map.get($thisComponentTokens, visible);
    width: fit-content;
    min-width: 3ch;
    border: none;
    font-family: Roche Sans, sans-serif;
    outline: none;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-indent: 0.5rem;
    visibility: if($visible, visible, hidden);
    box-shadow: map.get($thisComponentTokens, shadow);
    background-color: map.get($thisComponentTokens, backgroundColor);
    color: map.get($thisComponentTokens, textColor);
    width: map.get($thisComponentTokens, width);
    @include displayTextStyle(map.get($thisComponentTokens, textStyle));

    &.increment {
        text-indent: 0;
        text-align: center;
    }

    &.hidden {
        display: none;
    }

    /* Removed for now, likely useless
            &.active {
                margin-bottom: map.get($thisComponentTokens, marginBottom)
            }
            */
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: textInput;

    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include BuildStyles($thisComponentTokens);
    }



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////














    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug("- states found!");

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug("      -> Generated &." +$stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug("- breakpoints found!");

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug("   - " +$breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug("- orientations found!");

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug("   - " +$orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug("- no orientation found.");
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug("Building  " +$className+":");

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug("- layouts found!");

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug("   - " +$layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")} {
                @debug("      -> Generated ." +$layoutName+" ." +$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug("- no layout found.");
    }
}
