@use 'sass:map';
@use "../button.class.scss" as _Button;
@use '../../../themeFactory/utils.scss' as *;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
//
//    EditorialButton Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    @include _Button.layoutStyles($layoutTokens);
    @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens) {
    @include _Button.responsiveStyles($responsiveTokens);
    @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens) {
    @include _Button.stateStyles($stateTokens);

    & .label {
        @if (map.has-key($stateTokens, textStyle)) {
            @include displayTextStyle(map.get($stateTokens, textStyle));
        }

        cursor: pointer;
        cursor: map.get($stateTokens, cursor);
        color: map.get($stateTokens, textColor);

        p {
            margin: 0;
        }
    }

    @debug("      -> Provided stateStyles from EditorialButton");
}

@mixin BuildStyles($thisComponentTokens) {
    @include _Button.BuildStyles($thisComponentTokens);

    & .container {
        justify-content: map.get($thisComponentTokens, contentDistribution);
        justify-items: center;
        gap: map.get($thisComponentTokens, gap);

        @if (map.get($thisComponentTokens, assetPosition)==left) {
            & .asset {
                order: -1;
            }
        }

        & .label {
            @if (map.has-key($thisComponentTokens, textStyle)) {
                @include displayTextStyle(map.get($thisComponentTokens, textStyle));
            }

            cursor: pointer;
            cursor: map.get($thisComponentTokens, cursor);
            color: map.get($thisComponentTokens, textColor);

            p {
                margin: 0;
            }
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: editorialButton;

    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////











    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug("- states found!");

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug("      -> Generated &." +$stateName);
                @include stateStyles($stateTokens);
            }

            @if($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug("- breakpoints found!");

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug("   - " +$breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug("- orientations found!");

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug("   - " +$orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug("- no orientation found.");
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug("Building  " +$className+":");

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug("- layouts found!");

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug("   - " +$layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")} {
                @debug("      -> Generated ." +$layoutName+" ." +$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug("- no layout found.");
    }
}
