@use 'sass:map';
@use '../button.class.scss' as _Button;
@use '../../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../../../themeFactory/utils.scss' as *;

$colorValues: (
    dark: (iconColor: #ffffff,
        backgroundColor: #ffffff,
    ),
    light: (iconColor: #000000,
        backgroundColor: #000000,
    ),
);

@mixin dynamicStyles($thisComponentTokens) {
    $hasBackground: false;
    $colorContext: dark;
    $hasBackground: map-get($thisComponentTokens, hasBackground);
    $colorContext: map-get($thisComponentTokens, context);
    $colorValues: if(map-get($thisComponentTokens, lightContext),
            map.merge($colorValues, light, map.get($thisComponentTokens, lightContext)),
            $colorValues );
    $colorValues: if(map-get($thisComponentTokens, darkContext),
            map.merge($colorValues, dark, map.get($thisComponentTokens, darkContext)),
            $colorValues );

    @if ($hasBackground ==true) {
        box-shadow: map-get($thisComponentTokens, shadow);
        border-width: map-get($thisComponentTokens, borderSize);
        background-color: map.get($colorValues, #{$colorContext}, backgroundColor);
        @include paintSVG(map.get($colorValues, #{$colorContext}, iconColor));
    }

    @else {
        box-shadow: none;
        border-width: 0;
        background-color: transparent;
        @include paintSVG(map.get($colorValues, #{$colorContext}, iconColor));
    }

    &.InputsliderControlBtn {
        padding: 0;
    }
}

@mixin layoutStyles($layoutTokens) {
    @include _Button.layoutStyles($layoutTokens);
    @include dynamicStyles($layoutTokens);
}

@mixin responsiveStyles($responsiveTokens) {
    @include _Button.responsiveStyles($responsiveTokens);
    @include dynamicStyles($responsiveTokens);
}

@mixin stateStyles($stateTokens) {
    @include _Button.stateStyles($stateTokens);
}

@mixin BuildStyles($thisComponentTokens) {
    @include _Button.stateStyles($thisComponentTokens);
    @include dynamicStyles($thisComponentTokens);
    border-radius: map-get($thisComponentTokens, borderRadius);
    padding: map-get($thisComponentTokens, padding);

    &.disabled {
        // ToDo: remove after fixing state styling
        cursor: auto;
        opacity: 0.5;
    }

    & .container {
        & .asset {
            width: map.get($thisComponentTokens, iconDiameter);
            height: map.get($thisComponentTokens, iconDiameter);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: iconButton;
    $colorValues: (
        dark: map-get($thisComponentTokens, darkContext),
        light: map-get($thisComponentTokens, lightContext),
        ) !global;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens
    );

    &.blocked {
        pointer-events: none !important;
    }

    &.hidden {
        display: none !important;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT EDIT BELOW THIS LINE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
