@use 'sass:map';
@use '../../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../../../themeFactory/utils.scss' as *;

@mixin layoutStyles($layoutTokens) {
    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    & label {
        text-transform: uppercase;
    }

    &-component {
        padding: map.get($thisComponentTokens, componentPadding);
    }

    &-element {
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        margin: 0.4375rem 0;
        width: 100%;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            background: map.get($thisComponentTokens, trackBackground);
            border: 0.0125rem solid map.get($thisComponentTokens, trackBorderColor);
            border-radius: 0.5rem;
            cursor: pointer;
            height: 0.1875rem;
            width: 100%;
        }

        &::-moz-range-track {
            background: map.get($thisComponentTokens, trackBackground);
            border: 0.0125rem solid map.get($thisComponentTokens, trackBorderColor);
            border-radius: 0.5rem;
            cursor: pointer;
            height: 0.1875rem;
            width: 100%;
        }

        &::-ms-track {
            background: transparent;
            border-color: transparent;
            border-width: 0.5rem 0;
            color: transparent;
            cursor: pointer;
            height: 0.1875rem;
            width: 100%;
        }

        &-element:focus::-webkit-slider-runnable-track {
            background: map.get($thisComponentTokens, trackBackground);
        }

        &::-webkit-slider-thumb {
            appearance: none;
            background: map.get($thisComponentTokens, thumbBackground);
            border: 0.5rem solid map.get($thisComponentTokens, thumbBorderColor);
            border-radius: 1rem;
            cursor: pointer;
            height: 1rem;
            margin-top: -0.4375rem;
            width: 1rem;
        }

        &::-moz-range-thumb {
            background: map.get($thisComponentTokens, thumbBackground);
            border: 0.5rem solid map.get($thisComponentTokens, thumbBorderColor);
            border-radius: 1rem;
            cursor: pointer;
            height: 1rem;
            width: 1rem;
        }

        &::-ms-thumb {
            background: map.get($thisComponentTokens, thumbBackground);
            border: 0.5rem solid map.get($thisComponentTokens, thumbBorderColor);
            border-radius: 1rem;
            cursor: pointer;
            height: 1rem;
            margin-top: 0;
            width: 1rem;
        }

        &::-ms-fill-lower {
            background: map.get($thisComponentTokens, thumbBackground);
            border: 0.0125rem solid map.get($thisComponentTokens, thumbBorderColor);
            border-radius: 0.1875rem;
        }

        &::-ms-fill-upper {
            background: map.get($thisComponentTokens, thumbBackground);
            border: 0.0125rem solid map.get($thisComponentTokens, thumbBorderColor);
            border-radius: 0.1875rem;
        }

        &-element:focus::-ms-fill-lower {
            background: map.get($thisComponentTokens, thumbBackground);
        }

        &-element:focus::-ms-fill-upper {
            background: map.get($thisComponentTokens, thumbBackground);
        }
    }

    &-dots {
        display: flex;
        justify-content: space-between;
        margin: 0.375rem 0.4375rem 0;
    }

    &-dot {
        background-color: map.get($thisComponentTokens, trackBackground);
        border-radius: 50%;
        height: 0.25rem;
        width: 0.25rem;
    }

    &-legend {
        align-items: center;
        display: flex;
        font-size: 1rem;
        justify-content: space-between;
        margin-top: 0.5rem;

        & .label {
            color: map.get($thisComponentTokens, textColor);
            @include displayTextStyle(map.get($thisComponentTokens, textStyle));

            p {
                margin: 0;
            }
        }

        &-item {
            display: flex;
            flex: 1;

            & .icon {
                height: map.get($thisComponentTokens, iconDiameter);
                width: map.get($thisComponentTokens, iconDiameter);
            }

            & svg,
            & svg * {
                fill: currentColor;
            }
        }

        &-middle {
            justify-content: center;
            margin: 0 map.get($thisComponentTokens, componentPadding);
        }

        &-end {
            justify-content: end;
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: input-slider;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @include stateStyles($stateTokens);
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
