@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../button/iconButton/iconButton.class.scss' as _Button;

@mixin layoutStyles($layoutTokens) {
    @debug ('      -> Provided layoutStyles');
    @if (map-get($layoutTokens, maxWidth)) {
        max-width: map-get($layoutTokens, maxWidth);
        & .playerContainer {
            @if (map-get($layoutTokens, maxWidth)) {
                max-width: map-get($layoutTokens, maxWidth);
            }
        }
    }
    @if (map-get($layoutTokens, maxHeight)) {
        & .playerContainer {
            max-height: map-get($layoutTokens, maxHeight);
        }
    }
}

@mixin responsiveStyles($responsiveTokens) {
    @debug ('      -> Provided responsiveStyles');

    // to refactor
    @if (map-get($responsiveTokens, wrapAlign) == true) {
        flex-wrap: wrap;
        flex-direction: row;
        display: flex;
        align-content: flex-end;
    }
    @if (map-get($responsiveTokens, test) == false) {
        flex-wrap: null;
        align-content: null;
    }

    @if (map-get($responsiveTokens, exitTokens)) {
        & .exit {
            margin: map-get($responsiveTokens, exitTokens, margin);
            width: map-get($responsiveTokens, exitTokens, square);
            height: map-get($responsiveTokens, exitTokens, square);
        }
    }
}

@mixin stateStyles($stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: map.get($thisComponentTokens, overlayTokens, background);
    }
    &.hidden {
        display: none;
    }
    & .hidden {
        visibility: hidden;
    }
    & .visible {
        visibility: visible;
    }

    & .exit {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .controlsContainer {
        display: flex;
        flex-direction: row;
        background-color: black;
        width: 100%;
        z-index: 5;

        .controls {
            margin: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                padding: 0;
            }

            @include _Button.BuildStyles(map.get($thisComponentTokens, controlTokens));
        }

        progress {
            margin-left: 0.5rem;
            width: 100%;
            align-self: center;
            cursor: pointer;
        }
    }

    .playerContainer {
        position: relative;
        max-height: 100%;
        width: 100%;
        display: flex;
        > button {
            position: absolute;
            fill: white;
            z-index: 1;
            top: 0;
            left: 0;
        }

        > button,
        > button * {
            width: 100% !important;
            height: 100% !important;
        }
    }
    video {
        width: 100%;
        height: 100%;
        display: flex;
        &.vertical {
            object-fit: cover;
        }
        &.horizontal {
            object-fit: contain;
        }
    }

    &.fullscreen {
        position: absolute;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 100;
        align-content: flex-end;
        .playerContainer {
            width: 100%;
            height: 100%;
        }
        & video {
            &.vertical {
                height: 100%;
            }
            &.horizontal {
                width: 100%;
            }
            object-fit: contain;
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: videoPlayer;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
