@use 'sass:map';

@mixin layoutStyles($layoutTokens) {
    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    background-color: map-get($thisComponentTokens, background-color);
    border-radius: .5em;
    box-shadow: .25em .25em .5em 0 rgba(0, 0, 0, 0.16),
        .75em .75em 1em 0 rgba(0, 0, 0, 0.16);
    color: map-get($thisComponentTokens, font-color);
    display: none;
    font-family: 'Roche Sans', system-ui, sans-serif;
    font-size: 1rem;
    left: 50%;
    padding: 2em;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    &.timer-show {
        display: block;
    }

    &-title {
        font-size: 2em;
        font-weight: 400;
    }

    &-clock {
        font-size: 5em;
        font-weight: 700;
        margin-top: .25em;
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: timer;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
