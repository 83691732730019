@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//     Group Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    @if (map-get($layoutTokens, showOnlyLastChild)) {
        &:not(.persistent) {
            &:not(:last-child) {
                display: none;
            }
        }
        &:has(.vertical) {
            max-height: 100%;
        }
    }

    @if (map-get($layoutTokens, hideNonVideoElements)) {
        &:not(:has(video)) {
            display: none;
        }
    }

    &.expands > * {
        @if (map.get($layoutTokens, forcedWidth)) {
            &:has(.media) {
                max-width: map.get($layoutTokens, forcedWidth);
            }
        }
    }
}

@mixin responsiveStyles($responsiveTokens) {
}

@mixin stateStyles($stateTokens) {
}

@mixin BuildStyles($thisComponentTokens) {
    &.hasMargins {
        margin: map.get($thisComponentTokens, margin);
        gap: map.get($thisComponentTokens, margin) map.get($thisComponentTokens, margin);
    }

    &:not(.expands) {
        &.hasFixedWidthChildren > * {
            width: map.get($thisComponentTokens, width);
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: group;

    .#{$themeName} .#{$className} {
        display: flex;
        flex-direction: column;

        &.hasMargins {
            margin: map.get($thisComponentTokens, margin);
            gap: map.get($thisComponentTokens, margin);
        }

        &:not(.hasMargins) {
            gap: 0;
        }

        &.expands {
            align-items: stretch;

            & > * {
                width: 100%;
            }
        }

        &.left {
            align-items: flex-start;
        }

        &.right {
            align-items: flex-end;
        }

        &.center {
            align-items: center;
        }

        &.hasFixedWidthChildren > * {
            max-width: 66.66%;
            width: map.get($thisComponentTokens, width);

            img {
                width: 100%;
            }

            // &.incrementer,
            //     &.label,
            //     &.linkContainer {
            //         margin-left: auto;
            //     }
            // }
        }

        &.row {
            align-items: start;
            flex-direction: row;
        }

        &.isWrapped {
            flex-wrap: wrap;
            flex-direction: row;

            &.left {
                justify-content: flex-start;
            }

            &.right {
                justify-content: flex-end;
            }

            &.center {
                justify-content: center;
            }
        }
    }

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
