@use 'sass:map';
@use '../editorialButton.class.scss' as _EditorialButton;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//    CheckButton Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    @include _EditorialButton.layoutStyles($layoutTokens);
}

@mixin responsiveStyles($responsiveTokens) {
    @include _EditorialButton.responsiveStyles($responsiveTokens);
}

@mixin stateStyles($stateTokens) {
    @include _EditorialButton.stateStyles($stateTokens);

    & .container {
        & input:checked ~ .checkbox .checkmark {
            border-color: map-get($stateTokens, checkmarkColor);
            box-shadow: map-get($stateTokens, checkmarkShadow);
        }

        & .checkbox {
            background-color: map-get($stateTokens, checkboxBackgroundColor);
            box-shadow: map-get($stateTokens, checkboxShadow);
        }
    }
}

@mixin BuildStyles($thisComponentTokens) {
    @include _EditorialButton.BuildStyles($thisComponentTokens);

    & .container {
        & input {
            display: none;
        }

        & .checkbox {
            height: map-get($thisComponentTokens, checkboxSize);
            width: map-get($thisComponentTokens, checkboxSize);
            box-shadow: map-get($thisComponentTokens, checkboxShadow);
            background-color: map-get($thisComponentTokens, checkboxBackgroundColor);
            display: inline-block;
            position: relative;
            & .checkmark {
                height: calc(1.25 * map-get($thisComponentTokens, checkboxSize));
                width: calc(0.75 * map-get($thisComponentTokens, checkboxSize));
                border: none;
                position: absolute;
                bottom: 0;
                left: calc(0.3 * map-get($thisComponentTokens, checkboxSize));
                bottom: calc(0.1 * map-get($thisComponentTokens, checkboxSize));
                box-shadow: map.get($thisComponentTokens, checkmarkShadow);
                color: map.get($thisComponentTokens, checkmarkColor);
            }
        }
        & input:checked ~ .checkbox .checkmark {
            transform: rotate(45deg);
            border-style: solid;
            border-top: none;
            border-left: none;
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: checkButton;
    @debug ('Building .' + $className + if($specificName, '.' + $specificName, ''));
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');
        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);
            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }
            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');
        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);
            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }
            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');
    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
