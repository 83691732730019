@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../../themeFactory/utils.scss' as *;
@use "../button/iconButton/iconButton.class.scss" as _iconButton;

@mixin layoutStyles($layoutTokens) {
    position: map.get($layoutTokens, position);
    display: map.get($layoutTokens, display);
}

@mixin responsiveStyles($responsiveTokens) {
    display: map.get($responsiveTokens, display);

    & .photoControls {
        $alignTop: map.get($responsiveTokens, controls, alignTop);

        @if($alignTop) {
            top: 0;
            transform: translateY(0);
            position: absolute;
        }

        @else {
            top: 50%;
            transform: translateY(-50%);
            position: fixed;
        }
    }

    & .item {
        .iconButton {
            &.close {
                display: map.get($responsiveTokens, controls, icons, close, display) !important;
            }

            &.fullscreen {
                display: map.get($responsiveTokens, controls, icons, fullscreen, display) !important;
            }

            &.zoom {
                display: map.get($responsiveTokens, controls, icons, zoom, display) !important;
            }
        }
    }
}

@mixin stateStyles($stateTokens) {}

@mixin BuildStyles($thisComponentTokens) {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;

    & .photoControls {
        position: absolute;
        display: flex;
        flex-direction: column;
        height: auto;
        gap: map.get($thisComponentTokens, panel, gap);
        transform: translateY(-50%);
        top: 50%;
        right: 0px;
        z-index: 27;

        >.item {
            display: flex;
            background-color: rgba(0, 0, 0, 25%);
            align-items: center;
            justify-content: center;
            width: fit-content;

            &.disabled {
                opacity: 0.7;
            }

            >.iconButton {
                @include _iconButton.BuildStyles(map.get($thisComponentTokens, controls, icons));

            }
        }
    }

    & .overlay {
        left: 0;
        z-index: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: map.get($thisComponentTokens, overlay, background);
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: mediaGallery;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
