@use 'sass:map';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//     Content Group Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    /*
    margin-left: map.get($layoutTokens, marginLeft);
    flex-wrap: map.get($layoutTokens, flexWrap);
    justify-content: map.get($layoutTokens, justifyContent);
    flex-direction: map.get($layoutTokens, flexDirection);
    @debug ('      -> Provided layoutStyles');
    */
}

@mixin responsiveStyles($responsiveTokens) {
    //@debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens) {
    //@debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens) {
    /*
    align-items: map.get($thisComponentTokens, alignItems);
    display: flex;
    flex-wrap: map.get($thisComponentTokens, flexWrap);
    flex-direction: map.get($thisComponentTokens, flexDirection);
    gap: 0;
    height: fit-content;
    justify-items: map.get($thisComponentTokens, justifyItems);
    justify-content: map.get($thisComponentTokens, justifyContent);
    margin-bottom: map.get($thisComponentTokens, marginBottom);
    margin-left: map.get($thisComponentTokens, marginLeft);

    width: map.get($thisComponentTokens, width);
    */

    // TODO implement highlights properly
    /*
    .highlight:has(.bubble) {
        width: 100%;
    }

    .highlight:has(button) {
        width: auto;
    }

    .highlight:has(a) {
        width: auto;
    }
    */

    // TODO move to Increment
    /*
    a:not(.increment, .decrement):first-of-type {
        margin-top: 1.5rem;
    }

    a:not(.increment, .decrement) {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    a:not(.increment, .decrement):last-of-type {
        margin-bottom: 1.5rem;
    }
    */
    @debug ('      -> Provided BuildStyles');
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: contentGroup;
    .#{$themeName} .#{$className} {
        padding-bottom: map.get($thisComponentTokens, paddingBottom);
        $marginSize: calc(100% - map.get($thisComponentTokens, chatWidth));
        &.chatUser {
            margin-left: $marginSize;
        }
        &.chatAvatar {
            margin-right: $marginSize;
        }
        &.extra {
            margin: 0;
        }
    }
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');
        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);
            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }
            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');
        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);
            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }
            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');
    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
