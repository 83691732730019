@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;
@use '../../themeFactory/utils.scss' as *;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//     [New Component] Class
//
//     this defines the class for this component
//     you can either:
//         - provide fixed value, that *never* change in any context
//         - provide token-based values, that may change (specific layout, specific component...)
//             If so, don't forget to provide a default value in the tokens.scss joint file
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    // Map here any token values within the layouts properties
    // Keep the $layoutTokens map name, just provide any property that appears within layouts rules
    // Example:
    //     justify-content: map.get($layoutTokens, justifyContent);
    //     display: map.get($layoutTokens, display);
    //     & .typeArea {
    //         display: map.get($layoutTokens, typeArea, display);
    //     }
    // You might include a layoutStyles mixin from a parent component here
    width: map-get($layoutTokens, width);
    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    // Map here any token values the responsiveness properties
    // Keep the $responsiveTokens map name, just provide any property that appears within responsive rules
    // Example:
    //     width: map.get($responsiveTokens, width);
    // You might include a responsiveStyles mixin from a parent component here
    width: map-get($responsiveTokens, width);
    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    // Map here any token values within the states properties
    // Keep the $stateTokens map name, just provide any property that appears within states rules
    // Example:
    //     background-color: map.get($stateTokens, backgroundColor);
    // You might include a stateStyles mixin from a parent component here
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    align-self: end;
    padding: map.get($thisComponentTokens, spacings);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: map.get($thisComponentTokens, spacings);

    & .valuesDebug {
        position: fixed;
        left: 0;
        background-color: rgba($color: #fff, $alpha: 0.8);
        padding: 1rem;
    }

    & .CTA {
        align-self: center;

        & .labelButton {
            min-width: 16rem;
        }
    }

    .menu {
        align-self: flex-end;
        // min-width: 30rem;
        padding: 0.5rem 0;
        overflow-y: auto;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        position: relative; // required to position the baseline child

        & button {
            position: relative; // required to position the baseline child below
            padding: 0.5rem 2rem;
            background: none;
            border: none;
            border-bottom: 0.25rem solid transparent;
            color: map.get($thisComponentTokens, contentColor);
            text-transform: capitalize;
            @include displayTextStyle(map.get($thisComponentTokens, menuTextStyle));
            transition: all 0.3s ease;
            cursor: pointer;

            &.active,
            &:hover {
                border-bottom: 0.25rem solid map.get($thisComponentTokens, activeColor);
                color: map.get($thisComponentTokens, activeColor);
            }
        }

        & .baseline {
            position: absolute;
            width: 100%;
            height: 0.125rem;
            background-color: map.get($thisComponentTokens, contentColor);
            bottom: 0.5rem; // padding bottom + border
        }
    }

    .subMenuContainer {
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        // min-width: 30rem;
        & .subMenu {
            overflow-y: auto;
            padding: 0.5rem 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 2rem;

            & button {
                background: none;
                border: 0.125rem solid map.get($thisComponentTokens, contentColor);
                color: map.get($thisComponentTokens, contentColor);
                text-transform: capitalize;
                @include displayTextStyle(map.get($thisComponentTokens, menuTextStyle));
                transition: all 0.3s ease;
                cursor: pointer;
                padding: 0.5rem 1rem;
                text-align: left;

                & .availability {
                    font-size: 0.9rem;
                    text-transform: lowercase;
                }

                &.active,
                &:hover {
                    background-color: map.get($thisComponentTokens, contentColor);
                    color: map.get($thisComponentTokens, activeSubMenuContent);

                    & .label {
                        color: map.get($thisComponentTokens, activeSubMenuContent);
                    }
                }

                &.required {
                    border: 0.125rem dashed map.get($thisComponentTokens, errorColor);
                }
            }
        }
    }

    .input-slider-component {
        width: 100%;
        max-width: 30rem;
        align-self: flex-end;
    }

    p.helper {
        color: map.get($thisComponentTokens, contentColor);
        @include displayTextStyle(map.get($thisComponentTokens, menuTextStyle));
        font-family: RocheSans, sans-serif;
        font-size: 0.9rem;
    }

    .voiceStyles p.helper {
        padding: 0 1rem;
    }

    .tabContent,
    .subTabContent {
        display: none;

        &.active {
            display: flex;
            flex-direction: column;
            gap: map.get($thisComponentTokens, spacings);
        }
    }
    & > .modal {
        position: absolute;
        top: 0;
        left: 0;
        .modalContainer {
            & .modalContent {
                margin: map.get($thisComponentTokens, contentAreaMargin);

                & .label {
                    color: map.get($thisComponentTokens, contentAreaColor);
                }
            }
        }
    }

    .tabContent.camera {
        flex-direction: row;

        .group {
            align-content: flex-start;
        }

        & .cameraPreview {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .helper {
                text-align: center;
            }

            & #UneeqCameras {
                height: 30dvh;
            }

            & rect.shot {
                transition: all 0.3s ease;
                stroke: rgba(map.get($thisComponentTokens, contentColor), 0.5);
                stroke-width: 0.25rem;
            }

            &.camera_face rect.shot.CloseUp,
            &.camera_shoulders rect.shot.MediumCloseUp,
            &.camera_torso rect.shot.MediumShot,
            &.camera_fullbody rect.shot.FullShot {
                stroke: map.get($thisComponentTokens, activeColor);
            }
        }
    }

    .tabContent.content {
        & .radioButton {
            &.customHumanizerContent {
                white-space: nowrap;
            }

            padding: 0.5rem;

            .container {
                display: flex;
                flex-direction: row-reverse;
            }

            &.active .radiomark {
                background-color: map.get($thisComponentTokens, contentColor);
            }
        }

        .gridContainer {
            display: flex;
            gap: 1rem;
            flex-direction: column;

            .form {
                display: grid;
                grid-template-areas: 'button input' '. error';
                align-items: center;
                grid-template-columns: max-content auto;
                gap: 0.5rem 1rem;

                .radioButton {
                    grid-area: button;
                }

                .textInput {
                    grid-area: input;
                    width: 100%;
                }

                .error {
                    grid-area: error;
                }
            }

            .preview {
                font-size: 2rem;
            }
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: humanizer;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
