@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//    ContentArea Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($themeName, $specificName, $layoutTokens) {
    display: if(map.get($layoutTokens, visible)==true, null, none);

    @if (map.get($layoutTokens, hideNonVideoElements) !=null) {
        & .group {
            &> :not(:has(video)) {
                display: none;
            }
        }
    }

    width: map.get($layoutTokens, width);

    & .bottomBar {
        display: map-get($layoutTokens, textInputArea);
    }

    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($themeName, $specificName, $responsiveTokens) {
    width: map.get($responsiveTokens, width);

    @if (map.get($responsiveTokens, expandForVideos)) {
        &:has(.group:last-child .media) {
            width: 100% !important;
        }
    }

    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($themeName, $specificName, $stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    $fadingHeight: 3.5rem; // @todo fix map.get($thisComponentTokens, fadingHeight)

    max-height: calc(100dvh - map.get($thisComponentTokens, negativeHeight));
    height: 100%;
    margin: map.get($thisComponentTokens, margin);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    text-align: left;
    overflow: visible;
    background-color: map.get($thisComponentTokens, backgroundColor);
    padding-top: map.get($thisComponentTokens, paddingTop);
    padding-left: map.get($thisComponentTokens, paddingHorizontal);
    padding-right: map.get($thisComponentTokens, paddingHorizontal);

    & .contentScrollArea {
        width: 100%;
        max-width: 61.25rem; // TODO Shall we tokenize this?
        height: 100%;
        margin: 0 auto;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            // TODO Component to be created
            width: 0.125rem;
            height: 0.313rem;
            opacity: 0.8;
        }

        &::-webkit-scrollbar-track {
            width: 0.25rem;
            padding-top: 1rem;
        }

        &::-webkit-scrollbar-thumb {
            background: #cccccc;
            border-radius: 1.5rem;
        }

        padding-right: map.get($thisComponentTokens, paddingHorizontal);
        padding-left: map.get($thisComponentTokens, paddingHorizontal);
        display: flex;
        flex-direction: column;
        justify-content: map.get($thisComponentTokens, alignContent);
        align-items: stretch;
        background-color: map.get($thisComponentTokens, backgroundColor);

        &.scrollTop {
            -webkit-mask-image: linear-gradient(to top,
                    transparent 0%,
                    map.get($thisComponentTokens, fadingColor) $fadingHeight //map.get($thisComponentTokens, fadingHeight)
                );
            mask-image: linear-gradient(to top,
                    transparent 0%,
                    map.get($thisComponentTokens, fadingColor) $fadingHeight //map.get($thisComponentTokens, fadingHeight)
                );
        }

        &.scrollMid {
            -webkit-mask-image: linear-gradient(to top,
                    transparent 0%,
                    map.get($thisComponentTokens, fadingColor) $fadingHeight,

                    //map.get($thisComponentTokens, fadingHeight),
                    map.get($thisComponentTokens, fadingColor) calc(100% - $fadingHeight),
                    //map.get($thisComponentTokens, fadingHeight)),
                    transparent 100%);
            mask-image: linear-gradient(to top,
                    transparent 0%,
                    map.get($thisComponentTokens, fadingColor) $fadingHeight,

                    //map.get($thisComponentTokens, fadingHeight),
                    map.get($thisComponentTokens, fadingColor) calc(100% - $fadingHeight),
                    //map.get($thisComponentTokens, fadingHeight)),
                    transparent 100%);
        }

        &.scrollBottom {
            -webkit-mask-image: linear-gradient(to top,
                    map.get($thisComponentTokens, fadingColor) 0%,
                    map.get($thisComponentTokens, fadingColor) calc(100% - 3.5rem),
                    transparent 100%);
            mask-image: linear-gradient(to top,
                    map.get($thisComponentTokens, fadingColor) 0%,
                    map.get($thisComponentTokens, fadingColor) calc(100% - 3.5rem),
                    transparent 100%);
        }
    }

    & .bottomBar {
        align-self: center;
        max-width: 61.25rem - 2rem;
        width: calc(100% - 2rem);

        & .textInput {
            width: 100%;
            margin: map.get($thisComponentTokens, margin);
        }
    }

    &.extra {
        & .contentScrollArea {
            .group:last-child:not(:has(.humanizer)) {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    &.chat {
        & .contentScrollArea {
            justify-content: end;
        }

        & .bottomBar {
            display: block;
        }
    }

    &.initial {
        & .group.expands {
            text-align: right;

            & .dropdown {
                justify-content: flex-end;
            }
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: contentArea;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($themeName, $specificName, $map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($themeName, $specificName, $stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($themeName, $specificName, $map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($themeName, $specificName, $map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($themeName, $specificName, $breakpointTokens);
            }

            // States within breakpoints
            @include build_States($themeName, $specificName, $breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($themeName, $specificName, $map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($themeName, $specificName, $map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($themeName, $specificName, $orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($themeName, $specificName, $orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($themeName, $specificName, $thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($themeName, $specificName, $layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($themeName, $specificName, $layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
