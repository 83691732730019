@use 'sass:map';

@mixin layoutStyles($layoutTokens) {
    background-color: map.get($layoutTokens, backgroundColor);
    margin: map.get($layoutTokens, margin);
    padding: map.get($layoutTokens, padding);
    &.topActionBar, &.bottomActionBar{
    > .overlay{
        margin: map.get($layoutTokens, margin);
        padding: map.get($layoutTokens, padding);
        background-color: map.get($layoutTokens, overlay, backgroundColor);
        display: if(map.get($layoutTokens, overlay, visible) == false, none, block);
    }}
    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    background-color: map.get($responsiveTokens, backgroundColor);
    margin: map.get($responsiveTokens, margin);
    padding: map.get($responsiveTokens, padding);
    &.topActionBar, &.bottomActionBar{
    >.overlay{
        margin: map.get($layoutTokens, margin);
        padding: map.get($layoutTokens, padding);
        background-color: map.get($responsiveTokens, overlay, backgroundColor);
        display: if(map.get($responsiveTokens, overlay, visible) == false, none, block);
    }}
    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    position: relative;
    margin: map.get($thisComponentTokens, margin);
    padding: map.get($thisComponentTokens, padding);
    background-color: map.get($thisComponentTokens, backgroundColor);
    &.topActionBar, &.bottomActionBar{
    > .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        padding: map.get($thisComponentTokens, padding);
        margin: map.get($thisComponentTokens, margin);
        background-color: map.get($thisComponentTokens, overlay, backgroundColor);
        display: if(map.get($thisComponentTokens, overlay, visible) == false, none, block);
    }}
    & > .left,
    > .center,
    > .right {
        z-index: 1;
        align-items: center;
        display: flex;
        gap: map.get($thisComponentTokens, spaceBetweenContent);
    }

    & > .left {
        justify-content: flex-start;
    }

    & > .center {
        justify-content: center;
    }

    & > .right {
        justify-content: flex-end;
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: bar;
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');
        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);
            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }
            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');
        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);
            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }
            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');
    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
