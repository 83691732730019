@use 'sass:color';
@use 'sass:map';
@use '../../themeFactory/utils.scss' as *;

@mixin captions($thisComponentTokens) {
    @each $type, $typeTokens in map.get($thisComponentTokens, types) {
        $backgroundColor: black;
        $backgroundOpacity: 1;

        @if (map.has-key($typeTokens, backgroundColor)) {
            $backgroundColor: map.get($typeTokens, backgroundColor);
        }

        @else {
            $backgroundColor: map.get($thisComponentTokens, types, solid, backgroundColor);
        }

        @if (map.has-key($typeTokens, backgroundOpacity)) {
            $backgroundOpacity: map.get($typeTokens, backgroundOpacity);
        }

        @else {
            $backgroundOpacity: map.get($thisComponentTokens, types, solid, backgroundOpacity);
        }

        @if ($backgroundColor ==null or $backgroundColor ==none) {
            // Webpack hack
            $backgroundColor: black;
        }

        $background: color.change($backgroundColor, $alpha: $backgroundOpacity);

        &[data-mode='#{$type}'] {
            display: map.get($typeTokens, display);

            & .caption-text {
                background-color: $background;

                & .label {
                    @if (map.get($typeTokens, textStyle)) {
                        @include displayTextStyle(map.get($typeTokens, textStyle));
                    }

                    color: map.get($typeTokens, textColor);
                    text-shadow: map.get($typeTokens, textShadow);
                }
            }

        }
    }
}

@mixin layoutStyles($layoutTokens) {
    @include captions($thisComponentTokens);
}

@mixin responsiveStyles($responsiveTokens) {
    @include captions($thisComponentTokens);
}

@mixin stateStyles($stateTokens) {
    @include captions($thisComponentTokens);
}

@mixin BuildStyles($thisComponentTokens) {
    $backgroundColor: #000;
    $backgroundOpacity: 1;

    @if (map.has-key($thisComponentTokens, types, solid, backgroundColor)) {
        $backgroundColor: map.get($thisComponentTokens, types, solid, backgroundColor);
    }

    @if (map.has-key($thisComponentTokens, types, solid, backgroundOpacity)) {
        $backgroundOpacity: map.get($thisComponentTokens, types, solid, backgroundOpacity);
    }

    @if ($backgroundColor ==null or $backgroundColor ==none) {
        // Webpack hack
        $backgroundColor: black;
    }

    $background: color.change($backgroundColor, $alpha: $backgroundOpacity);
    bottom: 0;
    display: map.get($thisComponentTokens, display);
    left: 0;
    margin-inline: auto;
    position: absolute;
    right: 0;
    width: fit-content;
    z-index: 4;

    &.toppy {
        bottom: 1.5rem;
    }

    & .caption-text {
        box-sizing: border-box;
        margin: map.get($thisComponentTokens, margin);
        padding: map.get($thisComponentTokens, padding);
        text-align: center;
        width: fit-content;
        background-color: $background;

        & .label {
            color: map.get($thisComponentTokens, textColor);
            text-shadow: map.get($thisComponentTokens, textShadow);
        }

        @include displayTextStyle(map.get($thisComponentTokens, textStyle));

        & p {
            margin: 0;
        }

        &-line {
            margin: 0;
            padding: 0;
        }
    }

    @include captions($thisComponentTokens);
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: caption;

    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include BuildStyles($thisComponentTokens);
    }



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////














    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug("- states found!");

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug("      -> Generated &." +$stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug("- breakpoints found!");

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug("   - " +$breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug("- orientations found!");

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug("   - " +$orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug("- no orientation found.");
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug("Building  " +$className+":");

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug("- layouts found!");

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug("   - " +$layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")} {
                @debug("      -> Generated ." +$layoutName+" ." +$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug("- no layout found.");
    }
}
