@use 'sass:map';
@use '../../themeFactory/tokens/breakpoints' as breakpoints;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 
//     Layout Info Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    & li {
        &.avatar {
            display: map.get($layoutTokens, displayAvatar);
        }

        &.chat {
            display: map.get($layoutTokens, displayChat);
        }

        &.extra {
            display: map.get($layoutTokens, displayExtra);
        }
    }

    @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens) {
    & li {
        &.landscape {
            display: map.get($responsiveTokens, displayLandscape);
        }

        &.portrait {
            display: map.get($responsiveTokens, displayPortrait);
        }

        &.extraSmall {
            display: map.get($responsiveTokens, displayExtraSmall);
        }

        &.small {
            display: map.get($responsiveTokens, displaySmall);
        }

        &.large {
            display: map.get($responsiveTokens, displayLarge);
        }

        &.extraLarge {
            display: map.get($responsiveTokens, displayExtraLarge);
        }
    }

    @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens) {
    //@debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens) {
    position: fixed;
    z-index: 2;
    list-style: none;
    background: none;
    color: red;

    //font-weight: map.get($semanticTokens, textStyles, action, familyWeight, fontWeight);
    //font-family: map.get($semanticTokens, textStyles, action, familyWeight, fontFace);
    & li {
        display: none;

        &.visible {
            display: block;
        }
    }


}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: layoutInfo; // EDIT THE CLASS NAME

    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include BuildStyles($thisComponentTokens); // This is externalized to be reused independently by children components
    }

    // DONE!
    //
    // Now add this file to Tokens/_theme_build.scss
    // - Add @use rule for this class
    // - Call the BuildClass mixin
    // Edit the tokens of this component if not done already.


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////














    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug("- states found!");

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug("      -> Generated &." +$stateName);
                @include stateStyles($stateTokens);
            }

            @include stateStyles($stateTokens);
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug("- breakpoints found!");

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug("   - " +$breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug("- orientations found!");

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug("   - " +$orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug("- no orientation found.");
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug("Building  " +$className+":");

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug("- layouts found!");

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug("   - " +$layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")} {
                @debug("      -> Generated ." +$layoutName+" ." +$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug("- no layout found.");
    }
}
