@use 'sass:map';
@use '../../themeFactory/utils.scss' as *;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//    Button Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens) {
    @if (map.get($layoutTokens, visible) !=null) {
        display: if(map.get($layoutTokens, visible), block, none);
    }

    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    @if (map.get($responsiveTokens, visible) !=null) {
        display: if(map.get($responsiveTokens, visible), block, none);
    }

    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    @if(map.get($stateTokens, context)) {
        $type: map.get($stateTokens, context);

        @if(map.get($stateTokens, hasBackground==true)) {
            background-color: map.get($stateTokens, #{$type}Context, backgroundColor);
        }

        @include paintSVG(map.get($stateTokens, #{$type}Context, iconColor));
    }

    @else {
        background-color: map.get($stateTokens, backgroundColor);
        @include paintSVG(map.get($stateTokens, iconColor));
    }

    border-color: map.get($stateTokens, borderColor);
    border-radius: map.get($stateTokens, borderRadius);
    border-width: map.get($stateTokens, borderSize);
    box-shadow: map.get($stateTokens, shadow);
    cursor: map.get($stateTokens, cursor);
    display: if(map.get($stateTokens, visible)==false, none, block);
    opacity: map.get($stateTokens, opacity);
    transition-duration: map.get($stateTokens, transitionDuration);
    transition-property: map.get($stateTokens, transitionProperties);
    @debug ('      -> Provided stateStyles from Button');
}

@mixin BuildStyles($thisComponentTokens) {
    outline: none !important;
    background-color: map.get($thisComponentTokens, backgroundColor);
    border-color: map.get($thisComponentTokens, borderColor);
    border-radius: map.get($thisComponentTokens, borderRadius);
    border-style: if(map.get($thisComponentTokens, borderStyle), map.get($thisComponentTokens, borderStyle), solid);
    border-width: map.get($thisComponentTokens, borderWidth);
    box-shadow: map.get($thisComponentTokens, shadow);
    color: map.get($thisComponentTokens, textColor);
    cursor: pointer;
    cursor: map.get($thisComponentTokens, cursor);
    display: if(map.get($thisComponentTokens, visible), block, none);
    opacity: map.get($thisComponentTokens, opacity);
    padding: map.get($thisComponentTokens, padding);
    transition-duration: map.get($thisComponentTokens, transitionDuration);
    transition-property: map.get($thisComponentTokens, transitionProperties);
    width: if(map.get($thisComponentTokens, expand), 100%, fit-content);

    & .container {
        align-items: center;
        display: grid;
        grid-auto-flow: column;

        @if (map.get($thisComponentTokens, assetPosition)==left) {
            & .asset {
                order: -1;
            }
        }
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: button;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');

        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }

            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);

    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');

        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);

            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }

            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);

    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');

        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);

            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }

            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    }

    @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');

    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');

        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);

            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    }

    @else {
        @debug ('- no layout found.');
    }
}
